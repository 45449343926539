import React from 'react'

export default function ModelMeshBunker({ props, group, nodes, materials }) {
    return (
        <group ref={group} {...props} dispose={null}>
            <group name="Sketchfab_Scene">
                <group name="Object_4" scale={0.1}>
                    <group name="GateCOg01" position={[-17.326, 14.644, -42.529]} rotation={[Math.PI, 0, 0]}>
                        <group name="GateExtractor1" position={[17.326, -14.644, 42.529]}>
                            <mesh name="GateExtractor1_Correspondence_0" castShadow receiveShadow geometry={nodes.GateExtractor1_Correspondence_0.geometry} material={materials.Correspondence} />
                        </group>
                    </group>
                    <group name="GateCOg02" position={[-17.326, 14.644, -38.53]}>
                        <group name="GateExtractor" position={[17.326, -14.644, 38.53]}>
                            <mesh name="GateExtractor_Correspondence_0" castShadow receiveShadow geometry={nodes.GateExtractor_Correspondence_0.geometry} material={materials.Correspondence} />
                        </group>
                    </group>
                    <group name="HatchControl" position={[-18.226, 24.297, -30.584]} rotation={[-Math.PI / 2, 0, 0]}>
                        <group name="ExitpolySurface428" position={[18.226, -36.486, 30.597]}>
                            <group name="ExitpolySurface429" position={[0, -4.369, -0.013]}>
                                <mesh name="ExitpolySurface429_Correspondence_0" castShadow receiveShadow geometry={nodes.ExitpolySurface429_Correspondence_0.geometry} material={materials.Correspondence} />
                            </group>
                            <group name="ExitpolySurface430" position={[0, 1.887, 3.009]} rotation={[-0.085, 0, 0]}>
                                <mesh name="ExitpolySurface430_Correspondence_0" castShadow receiveShadow geometry={nodes.ExitpolySurface430_Correspondence_0.geometry} material={materials.Correspondence} />
                            </group>
                            <group name="Stump" position={[2.835, 14.675, 1.676]} rotation={[-0.03, -0.002, 0.089]} scale={1.037}>
                                <group name="pasted__Volume28" position={[1.045, 1.576, -16.525]} rotation={[3.14, -1.028, 3.105]} scale={0.727}>
                                    <mesh name="pasted__Volume28_TreeMat_0" castShadow receiveShadow geometry={nodes.pasted__Volume28_TreeMat_0.geometry} material={materials.TreeMat} />
                                </group>
                                <group name="pasted__Volume29" position={[1.099, 1.308, -16.072]} rotation={[3.14, -1.028, 3.105]} scale={0.778}>
                                    <mesh name="pasted__Volume29_TreeMat_0" castShadow receiveShadow geometry={nodes.pasted__Volume29_TreeMat_0.geometry} material={materials.TreeMat} />
                                </group>
                                <group name="pasted__Volume30" position={[1.03, 0.562, -15.046]} rotation={[3.14, -1.028, 3.105]} scale={0.778}>
                                    <mesh name="pasted__Volume30_TreeMat_0" castShadow receiveShadow geometry={nodes.pasted__Volume30_TreeMat_0.geometry} material={materials.TreeMat} />
                                </group>
                                <group name="pasted__Volume31" position={[-4.119, 8.84, -18.053]} rotation={[3.14, -1.028, 3.105]} scale={0.496}>
                                    <mesh name="pasted__Volume31_TreeMat_0" castShadow receiveShadow geometry={nodes.pasted__Volume31_TreeMat_0.geometry} material={materials.TreeMat} />
                                </group>
                                <group name="pasted__Volume32" position={[-3.921, 11.105, -26.867]} rotation={[-0.729, -1.544, -0.834]} scale={0.458}>
                                    <mesh name="pasted__Volume32_TreeMat_0" castShadow receiveShadow geometry={nodes.pasted__Volume32_TreeMat_0.geometry} material={materials.TreeMat} />
                                </group>
                                <group name="pasted__Volume33" position={[1.386, 1.904, -32.363]} rotation={[-0.069, -1.352, -0.198]} scale={0.727}>
                                    <mesh name="pasted__Volume33_TreeMat_0" castShadow receiveShadow geometry={nodes.pasted__Volume33_TreeMat_0.geometry} material={materials.TreeMat} />
                                </group>
                                <group name="pasted__Volume34" position={[-3.957, 10.828, -26.405]} rotation={[-0.729, -1.544, -0.834]} scale={0.458}>
                                    <mesh name="pasted__Volume34_TreeMat_0" castShadow receiveShadow geometry={nodes.pasted__Volume34_TreeMat_0.geometry} material={materials.TreeMat} />
                                </group>
                                <mesh name="Stump_TreeMat_0" castShadow receiveShadow geometry={nodes.Stump_TreeMat_0.geometry} material={materials.TreeMat} />
                            </group>
                            <mesh name="ExitpolySurface428_Correspondence_0" castShadow receiveShadow geometry={nodes.ExitpolySurface428_Correspondence_0.geometry} material={materials.Correspondence} />
                        </group>
                    </group>
                    <group name="box1" position={[-35.988, 7.829, -16.41]} rotation={[2.147, 0, 0]}>
                        <group name="SirenSource" position={[35.988, -7.829, 16.41]}>
                            <group name="ReflectivePlane" position={[41.697, -14.022, 18.634]} scale={2.135}>
                                <mesh name="ReflectivePlane_LightReflectd_0" castShadow receiveShadow geometry={nodes.ReflectivePlane_LightReflectd_0.geometry} material={materials.LightReflectd} />
                            </group>
                            <mesh name="SirenSource_LightNTransparency_0" castShadow receiveShadow geometry={nodes.SirenSource_LightNTransparency_0.geometry} material={materials.LightNTransparency} />
                        </group>
                    </group>
                    <group name="ReelRotator01" position={[-29.173, -2.59, 5.816]} rotation={[0, 0, -1.733]}>
                        <group name="Reel" position={[5.506, 7.598, -9.258]} rotation={[0, Math.PI / 2, 0]}>
                            <mesh name="Reel_PultNPeripherals_0" castShadow receiveShadow geometry={nodes.Reel_PultNPeripherals_0.geometry} material={materials.PultNPeripherals} />
                        </group>
                    </group>
                    <group name="ReelRotator02" position={[-33.15, -2.59, 5.816]} rotation={[0, 0, -1.733]}>
                        <group name="Reel1" position={[-9.023, -2.575, 9.258]} rotation={[Math.PI / 2, -Math.PI / 9, Math.PI / 2]}>
                            <mesh name="Reel1_PultNPeripherals_0" castShadow receiveShadow geometry={nodes.Reel1_PultNPeripherals_0.geometry} material={materials.PultNPeripherals} />
                        </group>
                    </group>
                    <group name="PerphoratedDiskRotor01" position={[-27.271, 4.518, 44.236]} rotation={[1.636, 0, 0]}>
                        <group name="polySurface423">
                            <mesh name="polySurface423_ComputerNCam_0" castShadow receiveShadow geometry={nodes.polySurface423_ComputerNCam_0.geometry} material={materials.ComputerNCam} />
                        </group>
                    </group>
                    <group name="PerphoratedDiskRotor02" position={[-27.271, 4.518, 57.168]} rotation={[-1.636, 0, 0]}>
                        <group name="polySurface426">
                            <mesh name="polySurface426_ComputerNCam_0" castShadow receiveShadow geometry={nodes.polySurface426_ComputerNCam_0.geometry} material={materials.ComputerNCam} />
                        </group>
                    </group>
                </group>
                <primitive object={nodes._rootJoint} />
                <group name="ACUnit" position={[0, 0, 0.212]} scale={0.1}>
                    <group name="VentHandle" position={[-11.277, 11.247, 60.411]} rotation={[0, 0, -0.944]}>
                        <group name="Propeller" position={[11.277, -11.247, -60.411]}>
                            <mesh name="Propeller_ACUnitNSafe_0" castShadow receiveShadow geometry={nodes.Propeller_ACUnitNSafe_0.geometry} material={materials.ACUnitNSafe} />
                        </group>
                    </group>
                </group>
                <group name="ACUnit1" position={[2.187, 0, 0.212]} scale={0.1}>
                    <group name="VentHandle02" position={[-11.277, 11.247, 60.411]} rotation={[0, 0, -0.944]}>
                        <group name="Propeller_1" position={[11.277, -11.247, -60.411]}>
                            <mesh name="Propeller_ACUnitNSafe_0_1" castShadow receiveShadow geometry={nodes.Propeller_ACUnitNSafe_0_1.geometry} material={materials.ACUnitNSafe} />
                        </group>
                    </group>
                </group>
                <group name="BigScreenAnim01" position={[-3.207, -0.131, 2.159]} scale={0.1}>
                    <group name="TVScreenBig01_1" position={[-0.005, 0, 0]}>
                        <mesh name="TVScreenBig01_1_TvScreens_0" castShadow receiveShadow geometry={nodes.TVScreenBig01_1_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVScreenBig01_2" position={[-0.005, 0, 0]}>
                        <mesh name="TVScreenBig01_2_TvScreens_0" castShadow receiveShadow geometry={nodes.TVScreenBig01_2_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVScreenBig01_3" position={[-0.01, 0, 0]}>
                        <mesh name="TVScreenBig01_3_TvScreens_0" castShadow receiveShadow geometry={nodes.TVScreenBig01_3_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVScreenBig01_4" position={[-0.01, 0, 0]}>
                        <mesh name="TVScreenBig01_4_TvScreens_0" castShadow receiveShadow geometry={nodes.TVScreenBig01_4_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVScreenBig01_5" position={[-0.01, 0, 0]}>
                        <mesh name="TVScreenBig01_5_TvScreens_0" castShadow receiveShadow geometry={nodes.TVScreenBig01_5_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVScreenBig01_6" position={[-0.01, 0, 0]}>
                        <mesh name="TVScreenBig01_6_TvScreens_0" castShadow receiveShadow geometry={nodes.TVScreenBig01_6_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                </group>
                <group name="BigScreenAnim02" position={[-3.207, -0.131, 2.996]} scale={0.1}>
                    <group name="TVScreenBig01_1_1">
                        <mesh name="TVScreenBig01_1_TVsNshit_0" castShadow receiveShadow geometry={nodes.TVScreenBig01_1_TVsNshit_0.geometry} material={materials.TVsNshit} />
                    </group>
                    <group name="TVScreenBig01_2_1" position={[-0.01, 0, 0]}>
                        <mesh name="TVScreenBig01_2_TvScreens_0_1" castShadow receiveShadow geometry={nodes.TVScreenBig01_2_TvScreens_0_1.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVScreenBig01_3_1" position={[-0.01, 0, 0]}>
                        <mesh name="TVScreenBig01_3_TvScreens_0_1" castShadow receiveShadow geometry={nodes.TVScreenBig01_3_TvScreens_0_1.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVScreenBig01_4_1" position={[-0.01, 0, 0]}>
                        <mesh name="TVScreenBig01_4_TvScreens_0_1" castShadow receiveShadow geometry={nodes.TVScreenBig01_4_TvScreens_0_1.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVScreenBig01_5_1" position={[-0.01, 0, 0]}>
                        <mesh name="TVScreenBig01_5_TvScreens_0_1" castShadow receiveShadow geometry={nodes.TVScreenBig01_5_TvScreens_0_1.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVScreenBig01_6_1" position={[-0.01, 0, 0]}>
                        <mesh name="TVScreenBig01_6_TvScreens_0_1" castShadow receiveShadow geometry={nodes.TVScreenBig01_6_TvScreens_0_1.geometry} material={materials.TvScreens} />
                    </group>
                </group>
                <group name="AVGScreenAnim01" position={[-3.229, 0.366, 1.949]} rotation={[0, 0, -0.105]} scale={0.1}>
                    <group name="TVAVG01_01" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_01_TvScreens_0" castShadow receiveShadow geometry={nodes.TVAVG01_01_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_02" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_02_TvScreens_0" castShadow receiveShadow geometry={nodes.TVAVG01_02_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_03" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_03_TvScreens_0" castShadow receiveShadow geometry={nodes.TVAVG01_03_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_04" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_04_TvScreens_0" castShadow receiveShadow geometry={nodes.TVAVG01_04_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_05" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_05_TvScreens_0" castShadow receiveShadow geometry={nodes.TVAVG01_05_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_06" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_06_TvScreens_0" castShadow receiveShadow geometry={nodes.TVAVG01_06_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_07" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_07_TvScreens_0" castShadow receiveShadow geometry={nodes.TVAVG01_07_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_08" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_08_TvScreens_0" castShadow receiveShadow geometry={nodes.TVAVG01_08_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_09" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_09_TvScreens_0" castShadow receiveShadow geometry={nodes.TVAVG01_09_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_010">
                        <mesh name="TVAVG01_010_TvScreens_0" castShadow receiveShadow geometry={nodes.TVAVG01_010_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                </group>
                <group name="AVGScreenAnim02" position={[-3.229, 0.366, 2.368]} rotation={[0, 0, -0.105]} scale={0.1}>
                    <group name="TVAVG01_01_1" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_01_TvScreens_0_1" castShadow receiveShadow geometry={nodes.TVAVG01_01_TvScreens_0_1.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_02_1" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_02_TvScreens_0_1" castShadow receiveShadow geometry={nodes.TVAVG01_02_TvScreens_0_1.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_03_1" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_03_TvScreens_0_1" castShadow receiveShadow geometry={nodes.TVAVG01_03_TvScreens_0_1.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_04_1" position={[-0.006, 0, 0]}>
                        <mesh name="TVAVG01_04_TvScreens_0_1" castShadow receiveShadow geometry={nodes.TVAVG01_04_TvScreens_0_1.geometry} material={materials.TvScreens} />
                    </group>
                </group>
                <group name="AVGScreenAnim03" position={[-3.229, 0.366, 2.796]} rotation={[0, 0, -0.105]} scale={0.1}>
                    <group name="TVAVG01_01_2" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_01_TvScreens_0_2" castShadow receiveShadow geometry={nodes.TVAVG01_01_TvScreens_0_2.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_02_2" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_02_TvScreens_0_2" castShadow receiveShadow geometry={nodes.TVAVG01_02_TvScreens_0_2.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_03_2" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_03_TvScreens_0_2" castShadow receiveShadow geometry={nodes.TVAVG01_03_TvScreens_0_2.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_04_2" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_04_TvScreens_0_2" castShadow receiveShadow geometry={nodes.TVAVG01_04_TvScreens_0_2.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_05_1" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_05_TvScreens_0_1" castShadow receiveShadow geometry={nodes.TVAVG01_05_TvScreens_0_1.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_06_1" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_06_TvScreens_0_1" castShadow receiveShadow geometry={nodes.TVAVG01_06_TvScreens_0_1.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_07_1" position={[-0.003, 0, 0]}>
                        <mesh name="TVAVG01_07_TvScreens_0_1" castShadow receiveShadow geometry={nodes.TVAVG01_07_TvScreens_0_1.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_08_1" position={[-0.007, 0, 0]}>
                        <mesh name="TVAVG01_08_TvScreens_0_1" castShadow receiveShadow geometry={nodes.TVAVG01_08_TvScreens_0_1.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_09_1" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_09_TvScreens_0_1" castShadow receiveShadow geometry={nodes.TVAVG01_09_TvScreens_0_1.geometry} material={materials.TvScreens} />
                    </group>
                </group>
                <group name="AVGScreenAnim04" position={[-3.229, 0.366, 3.219]} rotation={[0, 0, -0.105]} scale={0.1}>
                    <group name="TVAVG01_01_3" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_01_TvScreens_0_3" castShadow receiveShadow geometry={nodes.TVAVG01_01_TvScreens_0_3.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_02_3" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_02_TvScreens_0_3" castShadow receiveShadow geometry={nodes.TVAVG01_02_TvScreens_0_3.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_03_3" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_03_TvScreens_0_3" castShadow receiveShadow geometry={nodes.TVAVG01_03_TvScreens_0_3.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_04_3" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_04_TvScreens_0_3" castShadow receiveShadow geometry={nodes.TVAVG01_04_TvScreens_0_3.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_05_2" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_05_TvScreens_0_2" castShadow receiveShadow geometry={nodes.TVAVG01_05_TvScreens_0_2.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_06_2" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_06_TvScreens_0_2" castShadow receiveShadow geometry={nodes.TVAVG01_06_TvScreens_0_2.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_07_2" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_07_TvScreens_0_2" castShadow receiveShadow geometry={nodes.TVAVG01_07_TvScreens_0_2.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVAVG01_08_2" position={[-0.01, 0, 0]}>
                        <mesh name="TVAVG01_08_TvScreens_0_2" castShadow receiveShadow geometry={nodes.TVAVG01_08_TvScreens_0_2.geometry} material={materials.TvScreens} />
                    </group>
                </group>
                <group name="ArmoredScreenAnim01" position={[-2.973, 0.555, 1.281]} rotation={[0, -0.428, -0.103]} scale={0.1}>
                    <group name="TvArmored2" position={[-0.01, 0, 0]}>
                        <mesh name="TvArmored2_TvScreens_0" castShadow receiveShadow geometry={nodes.TvArmored2_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TvArmored3" position={[-0.01, 0, 0]}>
                        <mesh name="TvArmored3_TvScreens_0" castShadow receiveShadow geometry={nodes.TvArmored3_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TvArmored4" position={[-0.003, 0, 0]}>
                        <mesh name="TvArmored4_TvScreens_0" castShadow receiveShadow geometry={nodes.TvArmored4_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TvArmored5" position={[-0.007, 0, 0]}>
                        <mesh name="TvArmored5_TvScreens_0" castShadow receiveShadow geometry={nodes.TvArmored5_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TvArmored6" position={[-0.01, 0, 0]}>
                        <mesh name="TvArmored6_TvScreens_0" castShadow receiveShadow geometry={nodes.TvArmored6_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TvArmored7" position={[-0.01, 0, 0]}>
                        <mesh name="TvArmored7_TvScreens_0" castShadow receiveShadow geometry={nodes.TvArmored7_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TvArmored8" position={[-0.01, 0, 0]}>
                        <mesh name="TvArmored8_TvScreens_0" castShadow receiveShadow geometry={nodes.TvArmored8_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TvArmored9" position={[-0.01, 0, 0]}>
                        <mesh name="TvArmored9_TvScreens_0" castShadow receiveShadow geometry={nodes.TvArmored9_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TvArmored10" position={[-0.007, 0, 0]}>
                        <mesh name="TvArmored10_TvScreens_0" castShadow receiveShadow geometry={nodes.TvArmored10_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                </group>
                <group name="SmallScreenAnim01" position={[-3.167, 0.658, 3.257]} rotation={[0, 0, -0.177]} scale={0.1}>
                    <group name="TVSMALL2" position={[-0.01, 0, 0]}>
                        <mesh name="TVSMALL2_TvScreens_0" castShadow receiveShadow geometry={nodes.TVSMALL2_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                    <group name="TVSMALL3" position={[-0.005, 0, 0]}>
                        <mesh name="TVSMALL3_TvScreens_0" castShadow receiveShadow geometry={nodes.TVSMALL3_TvScreens_0.geometry} material={materials.TvScreens} />
                    </group>
                </group>
                <group name="CompLights" position={[0, 0.001, 0]} scale={0.1}>
                    <group name="CompLightDeepRed" position={[-0.02, 0, 0]}>
                        <mesh name="CompLightDeepRed_LightNTransparency_0" castShadow receiveShadow geometry={nodes.CompLightDeepRed_LightNTransparency_0.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightBluish">
                        <mesh name="CompLightBluish_LightNTransparency_0" castShadow receiveShadow geometry={nodes.CompLightBluish_LightNTransparency_0.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightOrangish">
                        <mesh name="CompLightOrangish_LightNTransparency_0" castShadow receiveShadow geometry={nodes.CompLightOrangish_LightNTransparency_0.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightGreen" position={[-0.02, 0, 0]}>
                        <mesh name="CompLightGreen_LightNTransparency_0" castShadow receiveShadow geometry={nodes.CompLightGreen_LightNTransparency_0.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightYellowGreenish" position={[-0.02, 0, 0]}>
                        <mesh name="CompLightYellowGreenish_LightNTransparency_0" castShadow receiveShadow geometry={nodes.CompLightYellowGreenish_LightNTransparency_0.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightPurple" position={[-0.02, 0, 0]}>
                        <mesh name="CompLightPurple_LightNTransparency_0" castShadow receiveShadow geometry={nodes.CompLightPurple_LightNTransparency_0.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightRed">
                        <mesh name="CompLightRed_LightNTransparency_0" castShadow receiveShadow geometry={nodes.CompLightRed_LightNTransparency_0.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightOrange" position={[-0.02, 0, 0]}>
                        <mesh name="CompLightOrange_LightNTransparency_0" castShadow receiveShadow geometry={nodes.CompLightOrange_LightNTransparency_0.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightPurple1" position={[-0.02, -1.865, 0.938]}>
                        <mesh name="CompLightPurple1_LightNTransparency_0" castShadow receiveShadow geometry={nodes.CompLightPurple1_LightNTransparency_0.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightRed1" position={[-0.02, -0.934, 0.948]}>
                        <mesh name="CompLightRed1_LightNTransparency_0" castShadow receiveShadow geometry={nodes.CompLightRed1_LightNTransparency_0.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightGreen1" position={[-0.02, -0.934, 0]}>
                        <mesh name="CompLightGreen1_LightNTransparency_0" castShadow receiveShadow geometry={nodes.CompLightGreen1_LightNTransparency_0.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightBluish1" position={[-0.02, 0, 0.944]}>
                        <mesh name="CompLightBluish1_LightNTransparency_0" castShadow receiveShadow geometry={nodes.CompLightBluish1_LightNTransparency_0.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightBluish2" position={[-0.02, 0, 1.887]}>
                        <mesh name="CompLightBluish2_LightNTransparency_0" castShadow receiveShadow geometry={nodes.CompLightBluish2_LightNTransparency_0.geometry} material={materials.LightNTransparency} />
                    </group>
                </group>
                <group name="CompLights1" position={[0, 3.873, 3.049]} rotation={[Math.PI / 2, 0, 0]} scale={0.1}>
                    <group name="CompLightDeepRed_1" position={[-0.02, -32.367, 41.553]} rotation={[-Math.PI / 2, 0, 0]}>
                        <mesh name="CompLightDeepRed_LightNTransparency_0_1" castShadow receiveShadow geometry={nodes.CompLightDeepRed_LightNTransparency_0_1.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightBluish_1" position={[-0.02, -30.476, 39.662]} rotation={[-Math.PI / 2, 0, 0]}>
                        <mesh name="CompLightBluish_LightNTransparency_0_1" castShadow receiveShadow geometry={nodes.CompLightBluish_LightNTransparency_0_1.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightOrangish_1" position={[-0.02, -31.433, 42.487]} rotation={[-Math.PI / 2, 0, 0]}>
                        <mesh name="CompLightOrangish_LightNTransparency_0_1" castShadow receiveShadow geometry={nodes.CompLightOrangish_LightNTransparency_0_1.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightGreen_1" position={[-0.02, -30.486, 41.54]} rotation={[-Math.PI / 2, 0, 0]}>
                        <mesh name="CompLightGreen_LightNTransparency_0_1" castShadow receiveShadow geometry={nodes.CompLightGreen_LightNTransparency_0_1.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightYellowGreenish_1" position={[-0.02, -29.542, 40.596]} rotation={[-Math.PI / 2, 0, 0]}>
                        <mesh name="CompLightYellowGreenish_LightNTransparency_0_1" castShadow receiveShadow geometry={nodes.CompLightYellowGreenish_LightNTransparency_0_1.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightPurple_1" position={[-0.02, -28.61, 41.528]} rotation={[-Math.PI / 2, 0, 0]}>
                        <mesh name="CompLightPurple_LightNTransparency_0_1" castShadow receiveShadow geometry={nodes.CompLightPurple_LightNTransparency_0_1.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightRed_1" position={[-0.02, -29.554, 42.472]} rotation={[-Math.PI / 2, 0, 0]}>
                        <mesh name="CompLightRed_LightNTransparency_0_1" castShadow receiveShadow geometry={nodes.CompLightRed_LightNTransparency_0_1.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightOrange_1" position={[-0.02, -30.501, 43.419]} rotation={[-Math.PI / 2, 0, 0]}>
                        <mesh name="CompLightOrange_LightNTransparency_0_1" castShadow receiveShadow geometry={nodes.CompLightOrange_LightNTransparency_0_1.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightPurple1_1" position={[-0.02, -30.476, 42.466]} rotation={[-Math.PI / 2, 0, 0]}>
                        <mesh name="CompLightPurple1_LightNTransparency_0_1" castShadow receiveShadow geometry={nodes.CompLightPurple1_LightNTransparency_0_1.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightRed1_1" position={[-0.02, -30.488, 43.42]} rotation={[-Math.PI / 2, 0, 0]}>
                        <mesh name="CompLightRed1_LightNTransparency_0_1" castShadow receiveShadow geometry={nodes.CompLightRed1_LightNTransparency_0_1.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightGreen1_1" position={[-0.02, -31.42, 41.54]} rotation={[-Math.PI / 2, 0, 0]}>
                        <mesh name="CompLightGreen1_LightNTransparency_0_1" castShadow receiveShadow geometry={nodes.CompLightGreen1_LightNTransparency_0_1.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightBluish1_1" position={[-0.02, 0, 0.944]}>
                        <mesh name="CompLightBluish1_LightNTransparency_0_1" castShadow receiveShadow geometry={nodes.CompLightBluish1_LightNTransparency_0_1.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightBluish2_1" position={[0, -30.476, 41.549]} rotation={[-Math.PI / 2, 0, 0]}>
                        <mesh name="CompLightBluish2_LightNTransparency_0_1" castShadow receiveShadow geometry={nodes.CompLightBluish2_LightNTransparency_0_1.geometry} material={materials.LightNTransparency} />
                    </group>
                </group>
                <group name="CompLights2" position={[0, 0.542, 7.203]} rotation={[-Math.PI, 0, 0]} scale={0.1}>
                    <group name="CompLightDeepRed_2" position={[-0.02, 9.186, 73.92]} rotation={[Math.PI, 0, 0]}>
                        <mesh name="CompLightDeepRed_LightNTransparency_0_2" castShadow receiveShadow geometry={nodes.CompLightDeepRed_LightNTransparency_0_2.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightBluish_2" position={[0, 9.186, 70.138]} rotation={[Math.PI, 0, 0]}>
                        <mesh name="CompLightBluish_LightNTransparency_0_2" castShadow receiveShadow geometry={nodes.CompLightBluish_LightNTransparency_0_2.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightOrangish_2" position={[-0.02, 11.055, 73.92]} rotation={[Math.PI, 0, 0]}>
                        <mesh name="CompLightOrangish_LightNTransparency_0_2" castShadow receiveShadow geometry={nodes.CompLightOrangish_LightNTransparency_0_2.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightGreen_2" position={[0, 11.055, 72.026]} rotation={[Math.PI, 0, 0]}>
                        <mesh name="CompLightGreen_LightNTransparency_0_2" castShadow receiveShadow geometry={nodes.CompLightGreen_LightNTransparency_0_2.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightYellowGreenish_2" position={[0, 11.055, 70.138]} rotation={[Math.PI, 0, 0]}>
                        <mesh name="CompLightYellowGreenish_LightNTransparency_0_2" castShadow receiveShadow geometry={nodes.CompLightYellowGreenish_LightNTransparency_0_2.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightPurple_2" position={[-0.02, 12.918, 70.138]} rotation={[Math.PI, 0, 0]}>
                        <mesh name="CompLightPurple_LightNTransparency_0_2" castShadow receiveShadow geometry={nodes.CompLightPurple_LightNTransparency_0_2.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightRed_2" position={[-0.02, 12.918, 72.026]} rotation={[Math.PI, 0, 0]}>
                        <mesh name="CompLightRed_LightNTransparency_0_2" castShadow receiveShadow geometry={nodes.CompLightRed_LightNTransparency_0_2.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightOrange_2" position={[-0.02, 12.918, 73.92]} rotation={[Math.PI, 0, 0]}>
                        <mesh name="CompLightOrange_LightNTransparency_0_2" castShadow receiveShadow geometry={nodes.CompLightOrange_LightNTransparency_0_2.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightPurple1_2" position={[-0.02, 11.052, 71.076]} rotation={[Math.PI, 0, 0]}>
                        <mesh name="CompLightPurple1_LightNTransparency_0_2" castShadow receiveShadow geometry={nodes.CompLightPurple1_LightNTransparency_0_2.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightRed1_2" position={[0, 11.983, 72.974]} rotation={[Math.PI, 0, 0]}>
                        <mesh name="CompLightRed1_LightNTransparency_0_2" castShadow receiveShadow geometry={nodes.CompLightRed1_LightNTransparency_0_2.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightGreen1_2" position={[-0.02, 10.121, 72.026]} rotation={[Math.PI, 0, 0]}>
                        <mesh name="CompLightGreen1_LightNTransparency_0_2" castShadow receiveShadow geometry={nodes.CompLightGreen1_LightNTransparency_0_2.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightBluish2_2" position={[-0.02, 9.186, 72.026]} rotation={[Math.PI, 0, 0]}>
                        <mesh name="CompLightBluish2_LightNTransparency_0_2" castShadow receiveShadow geometry={nodes.CompLightBluish2_LightNTransparency_0_2.geometry} material={materials.LightNTransparency} />
                    </group>
                </group>
                <group name="CompLights3" position={[0, -0.843, 0]} scale={0.1}>
                    <group name="CompLightDeepRed_3" position={[-0.02, 0, 0]}>
                        <mesh name="CompLightDeepRed_LightNTransparency_0_3" castShadow receiveShadow geometry={nodes.CompLightDeepRed_LightNTransparency_0_3.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightBluish_3" position={[-0.02, 0, 0]}>
                        <mesh name="CompLightBluish_LightNTransparency_0_3" castShadow receiveShadow geometry={nodes.CompLightBluish_LightNTransparency_0_3.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightOrangish_3" position={[-0.02, 0, 0]}>
                        <mesh name="CompLightOrangish_LightNTransparency_0_3" castShadow receiveShadow geometry={nodes.CompLightOrangish_LightNTransparency_0_3.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightGreen_3" position={[-0.02, 0, 0]}>
                        <mesh name="CompLightGreen_LightNTransparency_0_3" castShadow receiveShadow geometry={nodes.CompLightGreen_LightNTransparency_0_3.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightYellowGreenish_3" position={[-0.02, 0, 0]}>
                        <mesh name="CompLightYellowGreenish_LightNTransparency_0_3" castShadow receiveShadow geometry={nodes.CompLightYellowGreenish_LightNTransparency_0_3.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightPurple_3" position={[-0.02, 0, 0]}>
                        <mesh name="CompLightPurple_LightNTransparency_0_3" castShadow receiveShadow geometry={nodes.CompLightPurple_LightNTransparency_0_3.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightRed_3" position={[-0.02, 0, 0]}>
                        <mesh name="CompLightRed_LightNTransparency_0_3" castShadow receiveShadow geometry={nodes.CompLightRed_LightNTransparency_0_3.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightOrange_3" position={[-0.02, 0, 0]}>
                        <mesh name="CompLightOrange_LightNTransparency_0_3" castShadow receiveShadow geometry={nodes.CompLightOrange_LightNTransparency_0_3.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightPurple1_3" position={[-0.02, -1.865, 0.938]}>
                        <mesh name="CompLightPurple1_LightNTransparency_0_3" castShadow receiveShadow geometry={nodes.CompLightPurple1_LightNTransparency_0_3.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightRed1_3" position={[-0.02, -0.934, 0.948]}>
                        <mesh name="CompLightRed1_LightNTransparency_0_3" castShadow receiveShadow geometry={nodes.CompLightRed1_LightNTransparency_0_3.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightGreen1_3" position={[-0.02, -0.934, 0]}>
                        <mesh name="CompLightGreen1_LightNTransparency_0_3" castShadow receiveShadow geometry={nodes.CompLightGreen1_LightNTransparency_0_3.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightBluish1_2" position={[-0.02, -0.012, 0.946]}>
                        <mesh name="CompLightBluish1_LightNTransparency_0_2" castShadow receiveShadow geometry={nodes.CompLightBluish1_LightNTransparency_0_2.geometry} material={materials.LightNTransparency} />
                    </group>
                    <group name="CompLightBluish2_3" position={[-0.02, 0, 1.887]}>
                        <mesh name="CompLightBluish2_LightNTransparency_0_3" castShadow receiveShadow geometry={nodes.CompLightBluish2_LightNTransparency_0_3.geometry} material={materials.LightNTransparency} />
                    </group>
                </group>
                <mesh name="GrassFloor_FloorGrass_0" castShadow receiveShadow geometry={nodes.GrassFloor_FloorGrass_0.geometry} material={materials.FloorGrass} position={[-0.066, -0.222, 0.095]} scale={[0.262, 0.128, 0.345]} />
                <mesh name="polySurface414_Roof_0" castShadow receiveShadow geometry={nodes.polySurface414_Roof_0.geometry} material={materials.Roof} position={[-0.657, 1.703, 0.914]} rotation={[0, 0, Math.PI]} scale={0.098} />
                <mesh name="polySurface419_MetalFloor_0" castShadow receiveShadow geometry={nodes.polySurface419_MetalFloor_0.geometry} material={materials.MetalFloor} position={[-1.246, 0.232, -2.476]} scale={0.1} />
                <mesh name="polySurface418_Wall_0" castShadow receiveShadow geometry={nodes.polySurface418_Wall_0.geometry} material={materials.Wall} position={[-1.246, 0.198, -2.476]} scale={0.1} />
                {/* <mesh name="polySurface2_TreeMat_0" castShadow receiveShadow geometry={nodes.polySurface2_TreeMat_0.geometry} material={materials.TreeMat} position={[0.283, 0.251, 0.175]} rotation={[-0.03, -0.002, 0.089]} scale={0.104} /> */}
                <mesh name="plinth_DoorsVents_0" castShadow receiveShadow geometry={nodes.plinth_DoorsVents_0.geometry} material={materials.DoorsVents} position={[-0.022, 0.017, -0.238]} scale={0.1} />
                <mesh name="RoofDetails_Correspondence_0" castShadow receiveShadow geometry={nodes.RoofDetails_Correspondence_0.geometry} material={materials.Correspondence} scale={0.1} />
                <mesh name="LampsNRooftop_LightNTransparency_0" castShadow receiveShadow geometry={nodes.LampsNRooftop_LightNTransparency_0.geometry} material={materials.LightNTransparency} scale={0.1} />
                <mesh name="ACUnit_ACUnitNSafe_0" castShadow receiveShadow geometry={nodes.ACUnit_ACUnitNSafe_0.geometry} material={materials.ACUnitNSafe} position={[0, 0, 0.212]} scale={0.1} />
                <mesh name="TrashCan_TrashCanMat_0" castShadow receiveShadow geometry={nodes.TrashCan_TrashCanMat_0.geometry} material={materials.TrashCanMat} position={[-0.492, 0.019, 1.48]} scale={0.1} />
                <mesh name="polySurface425_ComputerNCam_0" castShadow receiveShadow geometry={nodes.polySurface425_ComputerNCam_0.geometry} material={materials.ComputerNCam} scale={0.1} />
                <mesh name="TableNAblem_WoodNPaper_0" castShadow receiveShadow geometry={nodes.TableNAblem_WoodNPaper_0.geometry} material={materials.WoodNPaper} scale={0.1} />
                <mesh name="ButDrag_PultNPeripherals_0" castShadow receiveShadow geometry={nodes.ButDrag_PultNPeripherals_0.geometry} material={materials.PultNPeripherals} position={[-1.259, 0.033, -0.04]} scale={0.1} />
                <mesh name="Cables_TVsNshit_0" castShadow receiveShadow geometry={nodes.Cables_TVsNshit_0.geometry} material={materials.TVsNshit} scale={0.1} />
                <mesh name="polySurface421_Shadows_0" castShadow receiveShadow geometry={nodes.polySurface421_Shadows_0.geometry} material={materials.Shadows} scale={0.1} />
                {/* <mesh name="Object_7" geometry={nodes.Object_7.geometry} material={materials.GrassTransp} skeleton={nodes.Object_7.skeleton} scale={0.1} /> */}
                {/* <mesh name="Object_8" geometry={nodes.Object_8.geometry} material={materials.GrassTransp} skeleton={nodes.Object_8.skeleton} scale={0.1} /> */}
                {/* <mesh name="Object_9" geometry={nodes.Object_9.geometry} material={materials.GrassTransp} skeleton={nodes.Object_9.skeleton} scale={0.1} /> */}
            </group>
        </group>);
}
