import React, { useState } from 'react'
import './sidebar.css'
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import ReactDOM from 'react-dom';
import { UilPlay } from '@iconscout/react-unicons'
import { UilTrophy } from '@iconscout/react-unicons'
import { UilBook } from '@iconscout/react-unicons'
import { UilSignOutAlt } from '@iconscout/react-unicons'
import { UilUsersAlt } from '@iconscout/react-unicons'
import Toast from '../notification/toastComponent';
import axios from 'axios';
import { api_url } from '../../config';


export default function Sidebar(props) {
  const navigate = useNavigate();


  const createToast = (message, type, duration = 5000) => {
    const toastContainer = document.querySelector('.notifications');
    if (toastContainer) {
      const toast = <Toast key={Date.now()} message={message} type={type} duration={duration} />;
      ReactDOM.render(toast, toastContainer);
    }
  };
  const handleNotification = (data) => {
    createToast(data, 'success');
    // Handle the received notification in a custom way
  };



  const [cookies, setCookies, removeCookies] = useCookies();
  const logout = () => {
    removeCookies('SessionID');
    axios
      .get(api_url + '/verify/delete', {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.status) {

        }
      });
    handleNotification(" User logged out succesfully");
    setTimeout(() => {
      navigate('/login')
    }, 3000)
  }

  return (
    <div className="sidebar">
      <img src="" alt="" />
      <Link to={"/rulebook"}>< div className={(props.data == "1" ? "my-4 navitem rule active" : "my-4 navitem rule")}><UilBook size='50' /></div></Link>
      <Link to={"/game"}><div className={(props.data == "2" ? "my-4 navitem play active" : "my-4 navitem play")}><UilPlay size='50' /></div></Link>
      <Link to={"/leaderboard"}><div className={(props.data == "3" ? "my-4 navitem leader active" : "my-4 navitem leader")}><UilTrophy size='50' /></div></Link>
      
      <Link to={"/members"}><div className={(props.data == "6" ? "my-4 navitem members active" : "my-4 navitem members")}><UilUsersAlt size='50' /></div></Link>
      
      <button style={{ background: "none", border: "none" }} onClick={() => logout()}><div className="my-4 navitem signout"><UilSignOutAlt size='50' /></div></button>
      <img src="" alt="" />
    </div>
  )


}
