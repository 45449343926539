import { Html } from '@react-three/drei'
import React from 'react'
import styles from './RoomModel.module.css'

export default function ControlRoomHints({qHint}) {
  return (
    <group>

<mesh position={[9,5,-34]} scale={2} >
            <Html distanceFactor={0.8} position={[0, 10, 0.51]} rotation={[1.6,0,0]} transform>
              <button tabIndex={-1} className={styles.QsButtons}  onClick={() => qHint(1)}>
                <div className={styles.QsDiv} style={{ width: "750px", height: "750px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>


          <mesh position={[30.5,37,22]} scale={2} >
            <Html distanceFactor={0.8} position={[0, 0, 0.51]} transform>
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(2)}>
                <div className={styles.QsDiv} style={{ width: "750px", height: "1300px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>

          <mesh position={[9,5,-34]} scale={2} >
            <Html distanceFactor={0.8} position={[17.5, 13.79, 20.81]} rotation={[1.6,0,0]} transform>
              <button tabIndex={-1} className={styles.QsButtons}  onClick={() => qHint(3)}>
                <div className={styles.QsDiv} style={{ width: "750px", height: "750px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>


          <mesh position={[130,18.2,-44.5]} scale={7} >
            <Html distanceFactor={0.8} position={[0, 0, 0.51]} transform rotation={[0,1.57,0]}>
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(4)}>
                <div className={styles.QsDiv} style={{ width: "250px", height: "450px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>

          
          <mesh position={[130,17.2,25.5]} scale={7} >
            <Html distanceFactor={0.8} position={[0, 0, 0.51]} transform rotation={[1,0.85,-0.35]}>
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(5)}>
                <div className={styles.QsDiv} style={{ width: "450px", height: "450px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>

          
          
          <mesh position={[120,1.65,-118.5]} scale={7.5} >
            <Html distanceFactor={0.8} position={[0, 0, 0.51]} transform rotation={[0,-0.75,0]}>
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(6)}>
                <div className={styles.QsDiv} style={{ width: "450px", height: "550px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>




          <mesh position={[130,21.2,90]} scale={7} >
            <Html distanceFactor={0.8} position={[0, 0, 0.51]} transform rotation={[0,0.25,0]}>
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(7)}>
                <div className={styles.QsDiv} style={{ width: "420px", height: "250px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>

          

          <mesh position={[-120,-0.2,-105.5]} scale={15} >
            <Html distanceFactor={0.8} position={[0, 0, 0.51]} transform rotation={[0,1.57,0]}>
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(8)}>
                <div className={styles.QsDiv} style={{ width: "250px", height: "450px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>



    </group>
  )
}
