import { Html, useProgress } from '@react-three/drei';  
import * as THREE from 'three'
import React from 'react'
import './CSS/Loader.css'

function Loader(props) {
    const { progress } = useProgress();
    const customStyle = {
      '--p': progress.toFixed(0),
      position:"absolute"
    };


    //props contains style and colour

    return (
      <Html position={props.position} >
        <div className="range" style={customStyle}>
        <div className="range__label">Progress</div>
      </div>
      </Html>
    )
  }

export default Loader
