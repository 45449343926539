import { Html } from '@react-three/drei'
import React from 'react'
import styles from './BunkerModel.module.css'

export default function BunkerHints({qHint}) {
    return (
        <group name='hints'>

            {/* 1st */}
            <mesh position={[0.34, 1.22, -0.4]} scale={0.7}>
                <Html occlude={"blending"} distanceFactor={0.8} position={[2.7, 0, 0]} transform rotation={[0, 4.712, 0]} >
                    <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(1)}>
                        
                        <div className={styles.QsDiv} style={{ width: "400px", height: "480px" }}>
                            <p style={{ margin: "0 5% 0 5% " }}></p>
                        </div>
                    </button>
                </Html>
            </mesh>

            {/* 2st */}
            <mesh position={[-1.4, -0.5, 2.641]} scale={0.8}>
                <Html distanceFactor={0.25}  transform rotation={[-1.5, 0, 1.15]} >
                    <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(2)}>
                        <div className={styles.QsDiv} style={{ width: "400px", height: "400px"}}>
                            <p style={{ margin: "0 5% 0 5% " }}></p>
                        </div>
                    </button>
                </Html>
            </mesh>

            <mesh position={[-2.89, -0.65, -0.47]} scale={0.9}>
                <Html distanceFactor={0.25}  transform rotation={[-1.5, 1.5, -0.05]} >
                    <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(3)}>
                        <div className={styles.QsDiv} style={{ width: "400px", height: "480px"}}>
                            <p style={{ margin: "0 5% 0 5% " }}></p>
                        </div>
                    </button>
                </Html>
            </mesh>

            <mesh position={[-3, 0.389, 2]} scale={0.9}>
                <Html distanceFactor={0.25}  transform rotation={[-1.5, 1.5, -0.05]} >
                    <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(4)}>
                        <div className={styles.QsDiv} style={{ width: "400px", height: "480px"}}>
                            <p style={{ margin: "0 5% 0 5% " }}></p>
                        </div>
                    </button>
                </Html>
            </mesh>


            <mesh position={[-3, 0.54, 2.400]} scale={0.1}>
                <Html distanceFactor={0.25}  transform rotation={[-1.5, 1.5, -0.05]} >
                    <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(5)}>
                        <div className={styles.QsDiv} style={{ width: "400px", height: "480px"}}>
                            <p style={{ margin: "0 5% 0 5% " }}></p>
                        </div>
                    </button>
                </Html>
            </mesh>

            

            <mesh position={[-3, -0.95, 1.95]} scale={0.3}>
                <Html distanceFactor={0.25}  transform rotation={[-1.5, 0.5, -0.05]} >
                    <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(6)}>
                        <div className={styles.QsDiv} style={{ width: "400px", height: "480px"}}>
                            <p style={{ margin: "0 5% 0 5% " }}></p>
                        </div>
                    </button>
                </Html>
            </mesh>


            <mesh position={[-3, -1.1, 1.925]} scale={0.3}>
                <Html distanceFactor={0.25}  transform rotation={[-1.5, 0.5, -0.05]} >
                    <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(7)}>
                        <div className={styles.QsDiv} style={{ width: "400px", height: "480px"}}>
                            <p style={{ margin: "0 5% 0 5% " }}></p>
                        </div>
                    </button>
                </Html>
            </mesh>

            <mesh position={[-3, 0.45, 4.75]} scale={0.4}>
                <Html distanceFactor={0.25}  transform rotation={[-1.5, 1.5, -0.05]} >
                    <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(8)}>
                        <div className={styles.QsDiv} style={{ width: "400px", height: "480px"}}>
                            <p style={{ margin: "0 5% 0 5% " }}></p>
                        </div>
                    </button>
                </Html>
            </mesh>



            <mesh position={[-3, -0.01, 1.31]} scale={0.4}>
                <Html distanceFactor={0.25}  transform rotation={[-1.5, 1.5, -0.05]} >
                    <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(9)}>
                        <div className={styles.QsDiv} style={{ width: "150px", height: "1000px"}}>
                            <p style={{ margin: "0 5% 0 5% " }}></p>
                        </div>
                    </button>
                </Html>
            </mesh>

            <mesh position={[-3, 0.47, 3.65]} scale={0.3}>
                <Html distanceFactor={0.25}  transform rotation={[-1.5, 1.5, -0.05]} >
                    <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(10)}>
                        <div className={styles.QsDiv} style={{ width: "400px", height: "480px"}}>
                            <p style={{ margin: "0 5% 0 5% " }}></p>
                        </div>
                    </button>
                </Html>
            </mesh>

            <mesh position={[-3, -0.5, 3.7]} scale={0.3}>
                <Html distanceFactor={0.25}  transform rotation={[-1.5, 1.5, -0.05]} >
                    <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(11)}>
                        <div className={styles.QsDiv} style={{ width: "400px", height: "480px"}}>
                            <p style={{ margin: "0 5% 0 5% " }}></p>
                        </div>
                    </button>
                </Html>
            </mesh>

            <mesh position={[1, -0.06, 2.141]} scale={0.25}>
                <Html distanceFactor={0.25}  transform rotation={[-1.5, 1.5, -0.05]} >
                    <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(12)}>
                        <div className={styles.QsDiv} style={{ width: "400px", height: "480px"}}>
                            <p style={{ margin: "0 5% 0 5% " }}></p>
                        </div>
                    </button>
                </Html>
            </mesh>

            



        </group>
    )
}
