import React, { useState,useEffect } from 'react'
import './login.css'
import teaser from './square_teaser.mp4'
import { useNavigate } from 'react-router-dom';
import ccslogo from "./ccs.png"
import { api_url } from '../../config'
import axios from "axios";
export default function Login() {
  const navigate = useNavigate();
  const navigateToRulebook = () => {
    navigate(`/rulebook`)
  }
  const [error, setError] = useState('')
  const [dispError, setDispError] = useState(false)
  const [loading, setLoader] = useState(false)
  const [loginform, form] = useState(true)

  const getPoints = async () => {

    axios
      .get(api_url + '/verify', {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.status) {
          if(res.data.message==="hello")
          {
            navigate(`/key`)
          }
          else{
            navigate(`/game`);
          }

        } 
      });
  }
  useEffect(() => {
    getPoints();
  }, []);
  function submit(e) {
    let url = api_url + "/login"
    e.preventDefault();
    //axios.defaults.withCredentials = true;
    let data = {
      username: e.target.username.value,
      password: e.target.password.value,
    };
    if("code" in e.target && "value" in e.target.code)
    {
      data.code = e.target.code.value
    }
    setLoader(true)
    form(false)
    axios.post(url, data, {
      withCredentials: true,
    })
      .then(res => {
        if(res.data==="cool")
        {
          setLoader(false)
          setDispError(false)
          setError("")
          navigate(`/key`)
        }
        if(res.data==="Login Successful")
        {
          setLoader(false)
          setDispError(false)
          setError("")
          navigateToRulebook()
        }
        else if (res.data ==="You have been awarded 5 points for using a code")
        {
            // alert(res.data)
            setLoader(false)
            setDispError(false)
            setError("")
            navigateToRulebook()
        }
        else{
          setError(res.data);
          setDispError(true)
          setLoader(false)
          form(true)
        }
      }
      )
  }


  var [hcode, setHCode] = useState(false)

  function hchandle(e) {

    setHCode(e.target.checked)
    
  }

  return (
    <div className='logincont'>

      <div className="videocont">
        <video loop autoPlay muted>
          <source src={teaser} type="video/mp4" />
        </video>
        <div className="overlay"></div>
        <div className="caption">
          <img src={ccslogo} alt="CCS" className='ccs' />
          <h6 className='mt-1 tag'>PRESENTS</h6>
          <h1 className='title mt-2 nusar'>RAPTUS</h1>
        </div>
      </div>
      <div className="login">
        <div className='d-flex flex-column w-100 justify-content-center align-items-center h-100'>

          <h1 className='audio mt-5'>LOGIN</h1>
          <hr className='seperator' />
{loginform &&
          <form onSubmit={(e) => submit(e)} className ='d-flex flex-column align-items-center mt-3'>
            <input type="text" placeholder='EMAIL' name='username' />
            <input type="password" placeholder='TEAM PASSWORD' name='password' />
            <div className='mt-5'>
              <label htmlFor="">Got a hidden code?</label>
              <input type="checkbox" name="hiddenCode" className='cb' onChange={hchandle} />
            </div>
            <input type="text" placeholder='HIDDEN CODE' disabled={!hcode} name='code' />
            {dispError &&
              <center>
                <p className='error'>{error}</p>
              </center>
            }
            
            <button type="submit" className='Submit-btn w-100 mt-5'> SUBMIT</button>
          </form>
          }
          {loading && <div className='Loader'>
            <div className="loading"></div>
          </div>
          }
        </div>
      </div>
    </div>
  )


}
