import React, { useState, useEffect } from 'react';
import './topbar.css';

export default function Topbar({ res }) {
  const [points, setPoints] = useState('');
  const [team, setTeam] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (res) {
      setPoints(res.points);
      setTeam(res.team);
      setTime(calculateTimeRemaining(res.time));
      setMessage(res.message);
      setDate(res.time);
    }
  }, [res]);
  function calculateTimeRemaining(targetDate) {
    const currentDate = new Date();
    return Math.max(0, targetDate - currentDate);
  }
  useEffect(() => {
    const targetDate = new Date(date); // Convert date to a Date object
    const timerInterval = setInterval(() => {
      const remainingTime = calculateTimeRemaining(targetDate); // Pass the Date object
      if (remainingTime > 0) setTime(remainingTime);
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [date]);
  const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((time % (1000 * 60)) / 1000);

  return (
    <div className="topbar">
      <div>
        <h3>POINTS: {points}</h3>
        <h6>{team}</h6>
      </div>

      <div>
        <h6>{message}</h6>
        <h3>
          {hours > 0 && String(hours).padStart(2, '0') + 'h'}{' '}
          {minutes > 0 && String(minutes).padStart(2, '0') + 'm'}{' '}
          {seconds > 0 && String(seconds).padStart(2, '0') + 's'}
        </h3>
      </div>
    </div>
  );
}