import { Html } from '@react-three/drei'
import React from 'react'
import styles from './Final.module.css'


export default function FinalRoomHints({qHint}) {
  return (
    <group>
    <mesh position={[89,70,-0.25]} scale={3} >
    <Html distanceFactor={0.5} position={[0,0,0]} transform rotation={[1.57,3.14,0]} >
      <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(2)}>
        <div className={styles.QsDiv} style={{ width: "800px", height: "800px"}}>
          <p style={{ margin: "0 5% 0 5% " }}></p>
        </div>
      </button>
    </Html>
  </mesh>

  
  <mesh position={[-10,70,-0.25]} scale={5} >
    <Html distanceFactor={0.5} position={[0,0,0]} transform rotation={[1.57,3.14,0]} >
      <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(3)}>
        <div className={styles.QsDiv} style={{ width: "800px", height: "800px"}}>
          <p style={{ margin: "0 5% 0 5% " }}></p>
        </div>
      </button>
    </Html>
  </mesh>

  <mesh position={[10,15,-70]} scale={15} >
    <Html  distanceFactor={0.5} position={[0,0,0]} transform rotation={[2.2,3.14,0]} >
      <button tabIndex={-1} className={styles.QsButtons} onClick={() => qHint(4)}>
        <div className={styles.QsDiv} style={{ width: "1500px", height: "500px"}}>
          <p style={{ margin: "0 5% 0 5% " }}></p>
        </div>
      </button>
    </Html>
  </mesh>



  
  </group>

  

  )
}
