import React, { useEffect, useState } from 'react'
import { api_url } from '../../../config';
import styles from './CSS/Hint.module.css'
import classNames from "classnames"
import axios from "axios";


const Hint = (props) => {
    const [loading, setLoader] = useState(false)
    const[reply,setReply]=useState(false)
    const[message,setMessage]=useState('')


    const url = api_url + '/question/hint';
    useEffect(()=>{
      setLoader(true);
      axios.post(url, {
        HintAddress: props.HintAddress,
        Round: 2
      }, {
        withCredentials: true,
      })
          .then(res => {
            setLoader(false);
            if (res.data.status) {
                  setReply(true)
                  setMessage(res.data.Hint)
              }
          }
          )
      },[])
         

  
return (
    <div >
     <div className={styles.popup} style={{minHeight:"40vh"}}>
     <i className={classNames("fas", "fa-times" ,styles.popupClose)} onClick={()=>props.func()}></i>
     <h2 className={styles.popupHeading}>HINT!!</h2>
        

     {loading && (
        <div className={styles.Loader}>
            <div className={styles.loading}></div>
        </div>
     )}

     {reply&&(

         <p className={styles.popupContent}>
            {message}
        </p>
    )}
    
    </div>
    </div>
  )
}

export default Hint
