import React, { useEffect, useState } from 'react'
import { api_url } from '../../../config';
import styles from './CSS/QnA.module.css'
import axios from "axios";
import classNames from "classnames"

const QuestionPopup = (props) => {
    const[press,setPress]=useState(true);

    const[reply,setReply]=useState(false)
    const[message,setMessage]=useState('')
    const[background,setBackground]=useState('')

    const [loading, setLoader] = useState(false)
    const [form, setForm] = useState(true)    
    
    const Question=props.Question;




      const url = api_url + '/question/answer';
      const [data, setData] = useState({
        Answer: ""
      })
    
      function submit(e) {
        setLoader(true);
        setForm(false)
        e.preventDefault();
        axios.post(url, {
            QuestionNumber:Question.QuestionNumber,
            Answer: data.Answer
          },{withCredentials:true})
            .then(res => {
              setLoader(false);
              if (res.data.status) {
                    setReply(true)
                    setMessage(res.data.message)
                    setBackground('rgba(53, 158, 4, 1)')
                }
              else {
                    setReply(true)
                    setMessage(res.data.message)
                    setBackground('rgba(200, 4, 23, 1)')
              }
            }
            )
        }
           
      





      function handle(e) {
        const newdata = { ...data };
        newdata[e.target.id] = e.target.value;
        setData(newdata);
      }



  
return (
    <div>
     {press &&(   
     <div  >

        <div className={styles.popup} style={{marginTop: reply?"25vh":"14vh"}}>
          <i className={classNames("fas", "fa-times" ,styles.popupClose)}onClick={()=>props.func()}></i>
          

          {!reply &&(<>
          <h2 className={styles.popupHeading}>QUESTION</h2>
          <p className={styles.popupQuestion}>
            {Question.Question}
            <br/>
                <p className={styles.popupDescription}   dangerouslySetInnerHTML={{ __html: Question.Description }}>
                </p>
          </p>






          {form &&(
          <form onSubmit={(e) => submit(e)}>
            <input type="text" className={styles.popupInput} onChange={(e) => handle(e)} placeholder="Answer" value={data.Answer} id="Answer" autoComplete='off' required/>
            <button className={styles.popupSubmitButton} type="submit">Submit</button>
          </form>)}
          
          {loading && (<div className={styles.Loader}>
              <div className={styles.loading}></div>
            </div>
            )}
          </>
            )}
            
            {reply &&(
                <>
                <h2 className={styles.popupHeading} style={{color:background,margin:"20% 0 20% 0"}}>{message}
                </h2>
                </>
            )}


        </div>
    </div>
    )}


    

    </div>
  )
}

export default QuestionPopup
