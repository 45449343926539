import React, { useEffect, useState } from 'react'
import styles from '../CSS/QnA.module.css'
import classNames from "classnames"

const OnPressRuleB = () => {
    const[press,setPress]=useState(false);


    useEffect(() => {
        const onKeyDown = (e) => {
            if ((e.shiftKey===true) && (e.key === 'R'||e.key === 'r')){
                setPress(true)
            }
        }
        const onKeyUp = (e) => {
            // on purpose (only one key in condition)
            if ((e.key === 'R'||e.key === 'r') ){
                setPress(false)

                // props.buttonVisibility();
            }
        }
        document.addEventListener("keydown", onKeyDown);
        document.addEventListener("keyup", onKeyUp);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
            document.removeEventListener("keyup", onKeyUp);
        }
        // eslint-disable-next-line
    }, [])




return (
    <div>
      {press &&
       <div className={styles.popup} style={{minHeight:"40vh",marginTop:"18vh"}}>
       <h2 className={styles.popupHeading}>
          Rulebook
       </h2>
       <p className={styles.popupDescription} style={{width:"90%",fontSize:"18px",marginTop:"-1.5vh"}}>
       <br/><br/>
       &#8226; The format will be same as previous round. 
            <br/><br/>
            &#8226; This round will have 4 questions.  
            <br/><br/>
            &#8226; You'll be awarded points for each question on basis of speed and accuracy. 
            <br/><br/>
            &#8226; You can check yours and your competitors progress at leaderboard.
            <br/><br/>
            &#8226; Top 4 teams from each group will qualify for the next round.
            <br/><br/>
            <br/>
            <h4 style={{textDecoration:"underline"}}>Shortcuts :-</h4>
            <br/>
            Leaderboard - Shift + L&emsp;&emsp;&emsp;&emsp;
            Rulebook - Shift + R<br/>
            Profile - Shift + P&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;
            Escape Profile - Esc
       </p>
      </div>
    }
    </div>
  )
}

export default OnPressRuleB
