import { useEffect } from "react";
import { io } from 'socket.io-client';
import { api_url } from "./config";

const useRoundSocket = (customNotificationHandler, customRoundUpdateHandler, customQuestionUpdateHandler) => {
    useEffect(() => {
        const socket = io(api_url, {
            transports: ['websocket'],
            withCredentials: true,
        });
       
        const handleNotification = async (data) => {
            console.log('Received notification:', data);
            if (customNotificationHandler) {
                customNotificationHandler(data);
            }
        };

        const handleRoundUpdate = (data) => {
            console.log('Received round update:', data);
            if (customRoundUpdateHandler) {
                customRoundUpdateHandler(data);
            }
        };

        const handleQuestionUpdate = (data) => {
            console.log('Received question update:', data);
            if (customQuestionUpdateHandler) {
                customQuestionUpdateHandler(data);
            }
        }

        socket.on('notification', handleNotification);
        socket.on('question-solved', handleQuestionUpdate);
        socket.on('round', handleRoundUpdate);

        return () => {
            // Clean up the event listeners when the component unmounts
            socket.off('notification', handleNotification);
            socket.off('round', handleRoundUpdate);
            socket.off('question-solved', handleQuestionUpdate);
        };
    }, []);
};

export default useRoundSocket;
