import React, { useEffect } from 'react'
import './error.css'
import { useNavigate } from 'react-router-dom';

export default function Error(props) {

  const navigate=useNavigate()

  useEffect(() => {
    setTimeout(() => {
      var audio_object =  new Audio("/error.mp3");
       var playResult = audio_object.play();
        playResult.catch(e => {
            // console.log(e);
        });

    }, 2000);
  }
    , []);

  return (
    <>
    <div className="errorpage" onClick={() => { new Audio("/error.mp3").play(); }}>
      
      <div className='back'>
          <div className="container" id="container">
            <button className="learn-more" onClick={() =>navigate('/game')}>
              <span className="circle" aria-hidden="true">
                <span className="icon arrow"></span>
                <span class="button-text">Simon go <b>BACK</b> !!</span>
              </span>
            </button>
          </div>

      </div>
      
      <h1>OOPS!</h1>
      <h1>{props.message}</h1>
    </div>
    </>
  )
}
