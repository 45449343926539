import React, { useState, useEffect } from 'react'
import './rulebook.css'
import Sidebar from '../../../Partials/sidebar/Sidebar'
import Topbar from '../../../Partials/topbar/Topbar'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { api_url } from '../../../config'
export default function Rulebook() {
  const navigate = useNavigate();
  const getPoints = async () => {

    axios
      .get(api_url + '/verify', {
        withCredentials: true,
      })
      .then((res) => {
        if (!res.data.status) {
          //navigate(`/login`);
        }
      });
  }
  useEffect(() => {
    getPoints();
  }, []);

  return (
    <>
    <div className='wrapper commonrulebg overlay'>
        <ul className="notifications"></ul>
        <Sidebar data = "1"/>
          <div className='content justify-content-center align-items-center'>
          <h1 className='audio mb-4'>RULEBOOK</h1>
          <div className="rules">
            <div className='RulebookCont'>

            <p>
            &#8226; It is a team event and one team can consist of 1-4 people.
            <br/><br/>
            &#8226; All participants should join from laptop for  better experience.
            <br/><br/>
            &#8226; The event will comprise 4 levels. At the first level, all the registered teams will be assigned a room.
            <br/><br/>
            &#8226; Fixed number of teams will qualify for the next round, taking into account the number of questions solved by them and time taken for solving questions. 
            <br/><br/>
            &#8226; The format for round 2 will also be similar as round 1.
            <br/><br/>
            &#8226; Round 3 will be a collaboration round ::
            <br/>
            &emsp;&emsp;&#8226; In this round,there will be only 2 teams in each room.
            <br/>
            &emsp;&emsp;&#8226; Both the teams will get the same question and they will have to collaborate with each <br/>&emsp;&emsp;&emsp;other and pool their resources to reach solution.
            <br/>
            &emsp;&emsp;&#8226; In this round there will be mutual qualification as well as elimination.
            <br/>
            &emsp;&emsp;&#8226; The collaboration and communication between teams will take place on specific <br/>&emsp;&emsp;&emsp;channels in Raptus Discord server,so kindly join the same.

            <br/><br/>
            &#8226; Round 4 will be the final round
            <br/>
            &emsp;&emsp;&#8226; All the teams will be assigned the same room in this round. 
            <br/>
            &emsp;&emsp;&#8226; There will be a single question.
            <br/>
            &emsp;&emsp;&#8226; The fastest team to complete it will be the winner.

            <br/><br/><br/>
               <h3>Leaderboard</h3>
              <br/>
               &#8226; At each level there will be a leaderboard,which will display the progress of the teams at that level and all these leaderboards will be made public by game's end.

               <br/><br/><br/>
               <h3>Discord</h3>
                <br/>


            
                &#8226; All the teams must join the official discord server for Raptus 
                <br/><br/>
                <a href='https://discord.gg/FvVVECS3' target='_blank'><h4 style={{textAlign:"center",textDecoration:"underline" ,fontSize:"18px"}}>Discord Link</h4></a>
                     

                <br/>
                         &#8226; Major information dissemination including hints,queries etc will be handled there.

                <br/><br/>  
                &#8226; Also collaboration for teams in round 3 will be done on specific channels in Discord server. 
                <br/><br/><br/><br/>

            <h3 style={{fontSize:"22px"}}><i><b>"To conquer the quest you gotta invade"</b></i></h3>

            </p>
            </div>
          </div>
          {/* Shortcut keys */}
          <h3 style={{margin:"2% 0 0% 0"}}>-: Shortcut Keys for popups inside Rounds :- </h3>
        <div className='shortcutsR' style={{marginTop:"2%",width:"600px"}}>
          <h1 >Shift + R : RuleBook  </h1>
          <h1>Shift + L : LeaderBoard  </h1>
        </div>           
        <div className='shortcutsR' style={{marginTop:"1%",width:"600px"}}>
          <h1 style={{marginLeft:"-6%"}}>Shift + P : Profile  </h1>
          <h1 style={{marginLeft:"-6%"}}>Esc : Escape Profile  </h1>
        </div>           
        </div>
    </div>
    </> 
  )

}
