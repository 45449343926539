import React, { useState, useEffect, useRef } from 'react'
import { useGLTF, useAnimations, Html } from '@react-three/drei'
import { api_url } from '../../config';
import styles from './Final.module.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DateTime } from 'luxon';
import useRoundSocket from '../../gameRoomSocket';
import Loader from './Components/Loader';
import styles2 from './Components/CSS/QnA.module.css'
import { useFrame } from '@react-three/fiber';
import ReactDOM from 'react-dom';
import Toast from '../../Partials/notification/toastComponent';
import FinalRoomHints from './FinalRoomHints';
export function FinalRoom(props) {



  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/finalroomfinal-transformed.glb')
  const { actions } = useAnimations(animations, group);

  const cubeRef = useRef();
  const handleMouseEnter = () => {
    document.body.style.cursor = 'pointer';
  };
  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    document.body.style.cursor = 'auto';
  };

  const roomRef = useRef();
  const jailRef = useRef();
  const platformRef = useRef();

  const navigate = useNavigate();
  const [qsns, setQsns] = useState({})
  const [qsns2, setQsns2] = useState({})
  const [time, setTime] = useState('')
  const [bunker, setBunker] = useState(false)
  const [message, setMessage] = useState('')
  const [dispErr, setDispErr] = useState(false)

  const [serverTime, setServerTime] = useState('');
  const [loading, setLoading] = useState(true);



  const fetchData = async () => {
    axios
      .get(api_url + '/round/fourth', {
        withCredentials: true,
      })
      .then((res) => {
        let data = res.data;
        setLoading(false)
        if (data.status) {
          setQsns(data.Questions);
          setTime(data.RoundEnd);
          setBunker(true);
          setServerTime(data.ServerTime);
          // console.log(data.Questions);
        }
        else {
          setMessage(data.message)
          setDispErr(true)
        }
      });
  }
  const fetchData2 = async () => {
    axios
      .get(api_url + '/round/fourth', {
        withCredentials: true,
      })
      .then((res) => {
        let data = res.data;
        setLoading(false)
        if (data.status) {
          setQsns(data.Questions);
          setQsns2(data.Questions);
          setTime(data.RoundEnd);
          setBunker(true);
          setServerTime(data.ServerTime);
          // console.log(data.Questions);
        }
        else {
          setMessage(data.message)
          setDispErr(true)
        }
      });
  }

  useEffect(() => {
    // animations[0].name = 0.0000000000000003;
    // actions["10-_wire_135006006Action.001"].play();

    //Countdown
    const timer = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    fetchData();


    return () => {
      clearInterval(timer);
    }


  }, [time]);




  const createToast = (message, type, duration = 5000) => {
    const toastContainer = document.querySelector('.notifications');
    if (toastContainer) {
      const toast = <Toast key={Date.now()} message={message} type={type} duration={duration} />;
      ReactDOM.render(toast, toastContainer);
    }
  };



  const handleNotification = (data) => {
    // console.log('Custom notification handler:', data);
    createToast('Info:' + data, 'info');
    // Handle the received notification in a custom way
  };
  const handleRound = (data) => {
    // console.log('Custom notification handler:', data);
    setTimeout(() => {
      navigate('/leaderboard')
    }, 3000);
    // Handle the received notification in a custom way 
  }
  const handleQuestionSolved = (data) => {
    fetchData2();
  }

  useRoundSocket(handleNotification, handleRound, handleQuestionSolved)


  useFrame((state, delta) => {




    if (bunker) {

      // console.log('delta', delta);
      cubeRef.current.rotation.z += 0.002;
      // cubeRef.current.scale.x = 1 + Math.sin(state.clock.getElapsedTime()) * 0.2;
      // cubeRef.current.scale.y = 1 + Math.sin(state.clock.getElapsedTime()) * 0.1;
      // cubeRef.current.material.emissiveIntensity = 4.5 + Math.sin(state.clock.getElapsedTime()) *4;
      roomRef.current.material.emissiveIntensity = Math.abs(Math.sin(state.clock.getElapsedTime())) * 2;
      var changeIntensity = 0.5 + Math.abs(Math.cos(state.clock.getElapsedTime() + 2)) * 0.75;
      jailRef.current.material.emissiveIntensity = changeIntensity;
      platformRef.current.material.emissiveIntensity = changeIntensity;
      cubeRef.current.material.emissiveIntensity = changeIntensity;
    }

    // console.log(    roomRef.current.material.emissiveIntensity );
  });



  const calculateCountdown = () => {
    const targetDate = DateTime.fromISO(time, { zone: 'utc' });
    const currentDate = DateTime.local();

    const timeDifference = targetDate.diff(currentDate, 'milliseconds').toObject().milliseconds;

    if (timeDifference <= 0) {
      return 'Round Ended';
    }

    if (timeDifference) {
      const duration = DateTime.fromMillis(timeDifference).toFormat('hh : mm : ss');

      return duration;
    }
  };

  const [countdown, setCountdown] = useState(calculateCountdown());




  //On hint press  
  const Qhint = (num) => {



    const index = qsns.Unsolved.findIndex(obj => obj.HintAddress === num);
    if (index === -1) {
      //nothing
    }
    else {
      props.HintAddress(num);
    }
  }


  const qsn = (num) => {
    // console.log("qsn", qsns.Unsolved);
    qsns.Unsolved.map((e, x) => {
      if (e.num == num) {
        props.setQ(e);
        props.setShowQ();
      }
    })
  }



  useEffect(() => {
    if (qsns.Unsolved)
      qsn(1)
  }, [qsns2])



  return (
    <group ref={group} {...props} dispose={null}>
      {loading &&
        <group >
          <Html position={[155, 428.2, -12.5]} style={{ margin: "0", padding: "0" }}>
            <div className={styles2.Loader} >
              <div className={styles2.loading} style={{ marginTop: "70vh" }}></div>
            </div>
          </Html>
          {/* <Loader  position={[45,78.2,-60.5]}/> */}
        </group>
      }
      {bunker &&
        <group name="Scene">

          <FinalRoomHints qHint={Qhint} />
          <group position={[150, 72.9, -42]}   >
            <Html distanceFactor={0.8} position={[0, 0, 0.51]} transform
              rotation={[0, -1.57, 0]} scale={26}  >
              <div className={styles.Watch} style={{ padding: "20px 43px" }}>
                {countdown}
              </div>
            </Html>
          </group>


          {/* Pure Room ka mesh */}
          <mesh name="1-_wire_000000000" ref={roomRef} castShadow receiveShadow geometry={nodes['1-_wire_000000000'].geometry} material={materials.wire_000000000} rotation={[Math.PI / 2, 0, 0]} />

          {/* Cube ka mesh */}
          <mesh name="10-_wire_135006006" ref={cubeRef} castShadow receiveShadow geometry={nodes['10-_wire_135006006'].geometry} material={materials.wire_135006006} rotation={[Math.PI / 2, 0, 2.863]} onClick={() => qsn(1)}
            onPointerEnter={handleMouseEnter}
            onPointerLeave={handleMouseLeave}
          />


          <mesh name="11-_wire_140088225" castShadow receiveShadow geometry={nodes['11-_wire_140088225'].geometry} material={materials.wire_140088225} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="12-_wire_143225087" castShadow receiveShadow geometry={nodes['12-_wire_143225087'].geometry} material={materials.wire_143225087} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="13-_wire_153228153" castShadow receiveShadow geometry={nodes['13-_wire_153228153'].geometry} material={materials.wire_153228153} rotation={[Math.PI / 2, 0, 0]} />
          <mesh position={[0, 0, 0]} name="14-_wire_213154229" castShadow receiveShadow geometry={nodes['14-_wire_213154229'].geometry} material={materials.wire_213154229} rotation={[Math.PI / 2, 0, 0]} />


          <mesh onPointerEnter={handleMouseEnter}
            onPointerLeave={handleMouseLeave}
            onClick={() => Qhint(1)}
            name="15-_wire_214228153" castShadow receiveShadow geometry={nodes['15-_wire_214228153'].geometry} material={materials.wire_214228153} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="16-_wire_214229166" castShadow receiveShadow geometry={nodes['16-_wire_214229166'].geometry} material={materials.wire_214229166} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="17-_wire_224086086" castShadow receiveShadow geometry={nodes['17-_wire_224086086'].geometry} material={materials.wire_224086086} rotation={[Math.PI / 2, 0, 0]} />

          {/* cube ki jali */}
          <mesh ref={jailRef} name="2-_wire_008008136" castShadow receiveShadow geometry={nodes['2-_wire_008008136'].geometry} material={materials.wire_008008136} rotation={[Math.PI / 2, 0, 0]}
          />

          <mesh name="3-_wire_055069083" castShadow receiveShadow geometry={nodes['3-_wire_055069083'].geometry} material={materials.PaletteMaterial001} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="4-_wire_069083055" castShadow receiveShadow geometry={nodes['4-_wire_069083055'].geometry} material={materials.wire_069083055} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="6-_wire_112169128" castShadow receiveShadow geometry={nodes['6-_wire_112169128'].geometry} material={materials.wire_112169128} rotation={[Math.PI / 2, 0, 0]} />

          {/* the lower platformRef */}
          <mesh name="7-_wire_115115115" ref={platformRef} castShadow receiveShadow geometry={nodes['7-_wire_115115115'].geometry} material={materials.wire_115115115} rotation={[Math.PI / 2, 0, 0]} />

        </group>
      }
      {dispErr &&
        <Html>
          <center>
            <div className='error'>{message}</div>
          </center>
        </Html>
      }
    </group>
  )
}

useGLTF.preload('/finalroomfinal-transformed.glb')
