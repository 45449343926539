import React, { useEffect, useState } from 'react'
import {useNavigate} from 'react-router-dom'
import { api_url } from '../../../../config';
import styles from '../CSS/Profile.module.css'
import styles2 from '../CSS/LeaderBoard.module.css'
import classNames from "classnames"
import OnPressLB from './Leaderboard';
import { DateTime } from 'luxon';
import axios from 'axios';



const EscButton = (props) => {
    
    const[press,setPress]=useState(false);
    const[profile,setProfile]=useState({});
    const[LB,setLB]=useState(false);
    const[LData,setLData]=useState({});
    const[team,setTeam]=useState({});

    const fetch=()=>{
        axios
        .get(api_url+'/model/profile',{
            withCredentials:true
        })
        .then((res)=>{
            if(res.data.status){
                      setProfile(res.data);
                  }
            });


            axios
            .get(api_url+'/model/popup/LB',{
                withCredentials:true,
            })
            .then((res)=>{
                      if(res.data.status){
                          setLData(res.data.LB);
                          currentTeam(res.data.LB,res.data.TeamName);
                        }
                })
    }
    
    useEffect(()=>{
        fetch();
    },[])




    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.keyCode == 27){
                fetch();
                setPress(true)
            }
        }
        
        //Countdown
        const timer = setInterval(() => {
            setCountdown(calculateCountdown());
        }, 1000);
  

        document.addEventListener("keydown", onKeyDown);
        return () => {
            clearInterval(timer);
            document.removeEventListener("keydown", onKeyDown);
        }
    }, [profile.RoundEnd])
  

    const currentTeam= (LB,TeamName)=>{
        LB.map((e,x)=>{
            if(e.TeamName===TeamName){
                e.Rank=x+1;
                setTeam(e);
            }
        })
    }



    const calculateCountdown = () => {
        const targetDate = DateTime.fromISO(profile.RoundEnd, { zone: 'utc' });
        const currentDate = DateTime.local();
    
        const timeDifference = targetDate.diff(currentDate, 'milliseconds').toObject().milliseconds;
        
        if (timeDifference <= 0) {
          return 'Round Ended';
        }
    
        if(timeDifference)
        {
        const duration = DateTime.fromMillis(timeDifference).toFormat('hh : mm : ss');
        return duration;}
      };

    const [countdown, setCountdown] = useState(calculateCountdown());

    



return (
    <div>
{press &&(
    <div className={styles.UnivBody} >


        {/* Escape profile */}
        {!LB &&(<>
            <div className={styles.popup}>
           
            <i className={classNames("fas", "fa-times" ,styles.popupClose)} onClick={()=>setPress(false)}></i>
            <h2 className={styles.popupHeading} style={{ marginTop: '30px' }}>Round : {profile.Round}</h2>
            <h2 className={styles.popupHeading}style={{ marginTop: '30px'}}>{profile.TeamName}</h2>
            <h2 className={styles.popupHeading} style={{ fontFamily: 'Roboto Flex', fontSize: '15px', fontWeight: '100', marginTop: '1px', marginBottom: '30px' }}>{profile.UserName}</h2>
            <p className={styles.popupContent}>TOTAL POINTS: {profile.Points}</p>
            <p className={styles.popupContent}>QUESTIONS SOLVED: {profile.QuestionsSolved}/{profile.TotalQuestions}</p>
            <p className={styles.popupContent}>TIME REMAINING: {countdown}</p>
            <button className={styles.popupButton} onClick={()=>{setLB(true);fetch()}} >CHECK LEADERBOARD</button>

        </div>
        </>)}


        {/* Leaderboard */}
        {LB && (
            <>  
            <div className={styles2.popup} style={{marginTop:"9%"}}>
                
                <button className={styles.backBtn} onClick={()=>setLB(false)}>
                    <span>&#8592;</span>
                </button>
                
                <i className={classNames("fas", "fa-times" ,styles.popupClose)} onClick={()=>{setLB(false);setPress(false);}}></i>
               
                <h2 className={styles2.popupHeading} style={{ marginTop: '6vh' }}>LeaderBoard</h2>



            <div className={styles2.content} style={{marginBottom:"-2vh"}}>
          
          {/* Current Team */}
          
                <div className={styles2.team} style={{width:"92%",margin:"6% 0 -3% 4%",background:"rgba(4, 90, 18, 0.251)"}}>
                    <h5 className={styles2.rank}>{team.Rank}</h5>
                    <h5 className={styles2.teamname}>{team.TeamName}</h5>
                    <h5 className={styles2.pts}>{team.Points}</h5>
                    <h5 className={styles2.time}>{team.TotalTime}</h5>
                </div>
               
              


            <div className={styles2.leaderboardcont} style={{paddingTop:"1vh",height:"38vh",marginLeft:"5%"}}>
                {LData.map((e,i)=>{
                    return(
                        <div className={styles2.team}>
                            <h5 className={styles2.rank}>{i+1}</h5>
                            <h5 className={styles2.teamname}>{e.TeamName}</h5>
                            <h5 className={styles2.pts}>{e.Points}</h5>
                            <h5 className={styles2.time}>{e.TotalTime}</h5>
                        </div>
                    )
                })}
            </div>

        </div>
    </div>
            </>
        )}
        

    </div>)}
    </div>
  )
}

export default EscButton
