import React, { useState, useEffect, useRef } from 'react'
import { useGLTF, useAnimations, Html } from '@react-three/drei'
import { api_url } from '../../config';
import styles from './Jail.module.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DateTime } from 'luxon';
import useRoundSocket from '../../gameRoomSocket';
import Loader from './Components/Loader';
import styles2 from './Components/CSS/QnA.module.css'
import ReactDOM from 'react-dom';
import Toast from '../../Partials/notification/toastComponent';
import JailHints from './JailHints';


export function JailRoom(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/jailroom-transformed.glb')
  
  const [qsns, setQsns] = useState({})
  const [time, setTime] = useState('')
  const [bunker, setBunker] = useState(false)
  const [message, setMessage] = useState('')
  const [dispErr, setDispErr] = useState(false)

  const [serverTime, setServerTime] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const fetchData = async () => {
    axios
    .get(api_url + '/round/third', {
      withCredentials: true,
    })
    .then((res) => {
        let data = res.data;
        setLoading(false)
        if (data.status) {
          setQsns(data.Questions);
          setTime(data.RoundEnd);
          setBunker(true);
          setServerTime(data.ServerTime);
        }
        else {
          setMessage(data.message)
          setDispErr(true)
        }
      });
  }


  useEffect(() => {
    //Countdown
    const timer = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    fetchData();


    return () => {
      clearInterval(timer);
    }


  }, [time]);


const handleMouseEnter = () => {
    document.body.style.cursor = 'pointer';
};
// Function to handle mouse leave event
const handleMouseLeave = () => {
  document.body.style.cursor = 'auto';
};

  const createToast = (message, type, duration = 5000) => {
    const toastContainer = document.querySelector('.notifications');
    if (toastContainer) {
      const toast = <Toast key={Date.now()} message={message} type={type} duration={duration} />;
      ReactDOM.render(toast, toastContainer);
    }
  };



  const handleNotification = (data) => {
    // console.log('Custom notification handler:', data);
    createToast('Info:' + data, 'info');
    // Handle the received notification in a custom way
  };
  const handleRound = (data) => {
    // console.log('Custom notification handler:', data);
    setTimeout(() => {
      navigate('/leaderboard')
    }, 3000); 
    // Handle the received notification in a custom way 
  }
  const handleQuestionSolved = (data) => {
    fetchData();
  }

  useRoundSocket(handleNotification, handleRound, handleQuestionSolved)


  const calculateCountdown = () => {
    const targetDate = DateTime.fromISO(time, { zone: 'utc' });
    const currentDate = DateTime.local();

    const timeDifference = targetDate.diff(currentDate, 'milliseconds').toObject().milliseconds;

    if (timeDifference <= 0) {
      return 'Round Ended';
    }

    if(timeDifference)
    {
    const duration = DateTime.fromMillis(timeDifference).toFormat('hh : mm : ss');

    return duration;}
  };

  const [countdown, setCountdown] = useState(calculateCountdown());






 //On hint press  
 const Qhint = (num) => {



  const index=qsns.Unsolved.findIndex(obj=>obj.HintAddress===num);
  if(index===-1){
    //nothing
  }
  else{
    props.HintAddress(num);
  }
}



  const qsn = (num) => {
    
    axios
    .get(api_url + '/round/third', {
      withCredentials: true,
    })
    .then((res) => {
        let data = res.data;
        if (data.status) {
          setQsns(data.Questions);
        }})
        console.log(qsns.Unsolved)

    //AlreadySolved Qsns
    qsns.Solved.map((e) => {
      if (e.num === num) {
        props.setAlreadyS();
      }
    })

    qsns.Unsolved.map((e,x) => {
      if (e.num == num) {
        props.setQ(e);
        props.setShowQ();
      }
    })


  }





  return (
    <group {...props} dispose={null}>
      {loading && 
          <group >
            <Html position={[66,428.2,-60.5]} style={{margin:"0",padding:"0"}}>
              <div className={styles2.Loader} >
                <div className={styles2.loading} style={{marginTop:"70vh"}}></div>
              </div>
            </Html>
            {/* <Loader  position={[45,78.2,-60.5]}/> */}
          </group>
      }
      

    {bunker &&
        <group>
      <mesh castShadow receiveShadow geometry={nodes['Hand-_Cuffs'].geometry} material={materials.PaletteMaterial001} position={[-1.055, 0.719, 2.481]} rotation={[-Math.PI / 2, 0, -1.985]} scale={0.004} />
      <mesh castShadow receiveShadow geometry={nodes['03_-_Volume_to_Mesh_-_HAS_MATERIAL'].geometry} material={materials.PaletteMaterial002} />
      <mesh castShadow receiveShadow geometry={nodes.WhatsApp_Image_2023_07_16_at_022553_w_trans.geometry} material={materials.WhatsApp_Image_2023_07_16_at_022553_w_trans} position={[-2.556, 1.135, 2.577]} rotation={[Math.PI / 2, 0, Math.PI]} scale={1.262} />
      <mesh castShadow receiveShadow geometry={nodes.pCube89_lambert1_0.geometry} material={materials.PaletteMaterial001} />
      <mesh castShadow receiveShadow geometry={nodes.Cube_Temp_Material_0.geometry} material={materials.Temp_Material} rotation={[-Math.PI / 2, 0, 0]} scale={[1.097, 1.097, 0.932]} />
      <mesh castShadow receiveShadow geometry={nodes.Cube001_Temp_Material002_0.geometry} material={materials['Temp_Material.002']} position={[-3.613, 0, 0]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={[1.032, 1.445, 0.878]} />
      <mesh castShadow receiveShadow geometry={nodes.Cube005_Bars_0.geometry} material={materials.Bars} position={[-1.12, 0.417, 0.484]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={1.032} />
      <mesh castShadow receiveShadow geometry={nodes.Cube006_Temp_Material006_0.geometry} material={materials['Temp_Material.006']} position={[-1.131, 0.474, 0.458]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[1.032, 1, 1.032]} />
      <mesh castShadow receiveShadow geometry={nodes.Cube008_Temp_Material007_0.geometry} material={materials['Temp_Material.007']} position={[-3.51, 0, 1.29]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={1.032} />
      <mesh castShadow receiveShadow geometry={nodes.Cube009_cesso000_0.geometry} material={materials['cesso.000']} position={[-0.456, -0.24, 2.071]} rotation={[-1.569, 0, Math.PI]} scale={1.032} />
      <mesh castShadow receiveShadow geometry={nodes.Cube012_Temp_Material001_0.geometry} material={materials['Temp_Material.001']} position={[-1.555, 0.58, 2.313]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={1.032} />
      <mesh castShadow receiveShadow geometry={nodes.Cube013_Temp_Material003_0.geometry} material={materials['Temp_Material.003']} position={[-2.824, 0.962, 0.182]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[0.394, 0.311, 0.565]} />
      <mesh castShadow receiveShadow geometry={nodes.Cylinder004_Bars001_0.geometry} material={materials['Bars.001']} position={[-1.536, 0.869, 2.569]} rotation={[0, 0, -Math.PI / 2]} scale={1.032} />
      <mesh castShadow receiveShadow geometry={nodes.LP_Temp_Material009_0.geometry} material={materials['Temp_Material.009']} position={[-1.362, 0.506, 0.459]} rotation={[-2.026, -0.909, -0.186]} scale={[0.851, 0.599, 0.599]} />
      <mesh castShadow receiveShadow geometry={nodes.Plane_posters_0.geometry} material={materials.posters} position={[-1.408, 1.31, 0.003]} rotation={[0, 0, 0.115]} scale={1.032} />
      <mesh castShadow receiveShadow geometry={nodes.Plane000_Temp_Material010_0.geometry} material={materials['Temp_Material.010']} position={[1.032, 0, 1.549]} rotation={[-Math.PI / 2, 0, 0]} scale={1.032} />
      <mesh   onPointerEnter={handleMouseEnter}
              onPointerLeave={handleMouseLeave}
                onClick={() => Qhint(6)}  castShadow receiveShadow geometry={nodes.SM_Key_Key_0.geometry} material={materials.material} position={[0.049, 2.127, 1.4]} rotation={[-1.505, -0.26, 1.866]} scale={1.113} />
      <mesh   onPointerEnter={handleMouseEnter}
              onPointerLeave={handleMouseLeave}
                onClick={() => Qhint(6)}  castShadow receiveShadow geometry={nodes.SM_KeyTag_KeyTag_0.geometry} material={materials.KeyTag} position={[0.053, 2.127, 1.451]} rotation={[-1.661, -0.208, -1.984]} scale={1.113} />
      <mesh   onPointerEnter={handleMouseEnter}
              onPointerLeave={handleMouseLeave}
                onClick={() => Qhint(6)}  castShadow receiveShadow geometry={nodes.SM_Ring_Ring_0.geometry} material={materials.Ring} position={[0.049, 2.127, 1.424]} rotation={[-Math.PI / 2, 0.227, 0]} scale={1.113} />
    
    
    <JailHints qHint={Qhint} />     
    <mesh position={[-1,0.88,-1.4]}  >
            <Html distanceFactor={0.8} position={[0, 0, 0.51]} transform rotation={[0,0,0.1]}>
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qsn(1)}>
                <div className={styles.QsDiv} style={{ width: "280px", height: "380px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>
     
     
      <mesh position={[-3.99,-0.305,1.8]}  >
            <Html distanceFactor={0.8} position={[0, 0, 0.51]} transform rotation={[0,1.57,0]}>
              <button tabIndex={-1} className={styles.QsButtons} onClick={() => qsn(2)}>
                <div className={styles.QsDiv} style={{ width: "80px", height: "170px" }}>
                  <p style={{ margin: "0 5% 0 5% " }}></p>
                </div>
              </button>
            </Html>
          </mesh>
    
    
    
    
    
    
    
    
    
    
    
    
    
      </group>
    }
    {dispErr &&
        <Html>
          <center>
            <div className='error'>{message}</div>
          </center>
        </Html>
      }
    </group>
  )
}

useGLTF.preload('/jailroom-transformed.glb')
